import React from "react"
import { graphql } from "gatsby"
import { SocialMediaPolicy } from '../components/social_media_policy'

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `SOCIAL MEDIA POLICY`, path: `social_media_policy` },
]

export default function SecurityPolicyPage({data}) {
  const members = data.site.siteMetadata.member.filter(m => !!m.twitter_url);
  return (
    <SocialMediaPolicy breadcrumbsData={breadcrumbsData} members={members} />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        member {
          name
          displayed_member
          twitter_url
          twitter_name
        }
      }
    }
  }
`
