import React from "react"
import Layout from "../../template/Layout"
import styled from "styled-components"
import Head from "../../head";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES, POLICY_STYLES } from "../../AppConst"
import { SpWrapper } from "../common/SpWrapper"
import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { SectionTitle } from '../common/SectionTitle'

/**
 * Style
 */
const Container = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  box-sizing: border-box;
  max-width: 960px;
`
const Wrapper = styled.div`
  ${POLICY_STYLES}
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: 32px;
  }
`

/**
 * Component
 */

type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  members: Array<any>
}

export const SocialMediaPolicy: React.VFC<Props> = ({
  breadcrumbsData,
  members,
}) => {
  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title="ソーシャルメディアポリシー | ネクストライブ株式会社"
        description="ネクストライブ株式会社のソーシャルメディアポリシーページです。ソーシャルメディアアカウントの取扱いについて掲載しています。"
      />
      <Container>
        <SpWrapper>
          <Wrapper>
            <SectionTitle title='social media policy' subTitle='ソーシャルメディアポリシー' />
            <section>
              <p>ネクストライブ株式会社（以下「当社」）はソーシャルメディア公式アカウント運営、および当社社員のソーシャルメディア参加において遵守すべき姿勢・行動について、「ネクストライブ株式会社 ソーシャルメディアポリシー」（以下「ポリシー」）を定め、遵守します。</p>
            </section>

            <section>
              <h3>ソーシャルメディアの定義</h3>
              <p>インターネットを利用して誰でも手軽に情報を発信し、相互のやりとりができる双方向のメディア（ブログ、 FacebookやTwitter等のSNS）とします。</p>
            </section>

            <section>
              <h3>ソーシャルメディアによる情報発信における「自覚と責任」</h3>
              <ol>
                <li>インターネットへの情報発信は、不特定多数の利用者がアクセス可能であることを常に意識します。</li>
                <li>一人ひとりの情報発信が、少なからず影響を持つ事を理解し十分に意識します。</li>
                <li>情報発信にあたっては、法令・利用するサービスの規定・当社独自の内部規定を厳格に遵守します。</li>
                <li>一度発信した情報は、完全に削除出来ない可能性がある事を理解し内容に責任を持ちます。</li>
                <li>誤った情報を発信したり、誤解を与える表現を行ったりした場合は、速やかにお詫びと訂正を行います。</li>
                <li>著作権、第三者の知的財産権、プライバシー権等の権利を尊重し、これらを侵害しないよう配慮します。</li>
                <li>ソーシャルメディアを通じ、社員と、ユーザーや社会とがコミュニケーションを通じて良い関係を構築し、当社の企業理念に沿った言動を行う事を常に意識します。</li>
                <li>取得した個人情報については、当社規定の個人情報保護方針に基づき適切に管理します。</li>
              </ol>
            </section>

            <section>
              <h3>当社の責任と見直し</h3>
              <p>日々進歩するインターネットの世界において、特有の文化・社会情勢を理解する努力を怠らず、ポリシーの内容を適宜見直し、ポリシーの形骸化を防ぐと共に継続的改善に努めます。ポリシーを遵守し継続する事が、情報・通信業を主力事業とする当社の責任であると考えています。</p>
            </section>

            <section>
              <h3>当社の運営アカウント</h3>
              <ul>
                <li><a href={"https://twitter.com/nextribe"} target="_blank" rel="noopener noreferrer">ワンだふる@NEXTRIBE</a></li>
                <li><a href={"https://twitter.com/01engineer_camp"} target="_blank" rel="noopener noreferrer">【公式】0→1 ENGINEER CAMP｜エンジニア育成</a></li>
                <li><a href={"https://twitter.com/otsutome"} target="_blank" rel="noopener noreferrer">オツトメ！｜完全無料のWeb勤怠管理サービス</a></li>
                {
                  members.filter(m => !!m.displayed_member && !!m.twitter_url && !m.twitter_url.endsWith("nextribe")).map((member, i) => {
                    return (
                      <li key={i}><a href={member.twitter_url} target="_blank" rel="noopener noreferrer">{member.twitter_name}</a></li>
                    )
                  })
                }
              </ul>
            </section>

            <section>
              <p>
                2022年6月16日 制定<br />
                ネクストライブ株式会社<br />
                代表取締役　狗巻 雄介
              </p>
            </section>
          </Wrapper>
        </SpWrapper>
      </Container>
    </Layout>
  )
}
